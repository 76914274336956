<template>
	<v-toolbar flat dense rounded :outlined="outlined" class="d-flex justify-end">

		<div v-if="updateMode" class="d-flex">

			<v-btn v-if="isTyping" icon @click="cancelChanges"><v-icon color="primary">mdi-close</v-icon></v-btn>
			<v-btn v-else icon @click="decreaseOrderQuantity"><v-icon>mdi-minus</v-icon></v-btn>

			<v-text-field ref="quantity" v-model="orderQty" class="font-weight-medium my-auto mx-4 " style="width:75px"
				type="number" solo dense flat outlined :hint="hint" hide-spin-buttons :loading="loading"
				hide-details="auto" @keyup.native.enter="saveChanges" @change="onChange" @focus="onFocus"
				@blur="onBlur" />

			<v-btn v-if="isTyping" icon @click.stop="saveChanges"><v-icon color="primary">mdi-check</v-icon></v-btn>
			<v-btn v-else icon @click="increaseOrderQuantity"><v-icon>mdi-plus</v-icon></v-btn>

		</div>

		<div v-else>
			<v-btn v-if="orderQty" color="primary" class="elevation-0" @click="updateItem">
				{{ orderQty }}
			</v-btn>
			<v-btn v-else="!orderQty" color="primary" class="elevation-0" @click="addItem">
				<v-icon>mdi-cart-outline</v-icon>
			</v-btn>
		</div>
	</v-toolbar>

</template>

<script>
import lodash from "lodash";

export default {
	name: "CartItemToolbar",

	props: {
		productCode: String,
		outlined: Boolean,
		label: String,
		minimalOrderQty: Number,
	},

	data() {
		return {
			loading: false,
			dataModified: false,
			orderQty: 0,
			updateMode: '',
			isTyping: false,
			processEventsDebounced: lodash.debounce(this.processEvents, 3000)
		};
	},

	computed: {
		cartItem() {
			return this.$store.getters["shoppingCart/cartItem"](
				this.productCode
			);
		},
		hint() {
			if (this.minimalOrderQty > 1) {
				return "per " + this.minimalOrderQty + " stuks";
			}
			return "";
		},
	},

	mounted() {
		if (this.cartItem) {
			this.orderQty = this.cartItem.quantity;
		}
	},

	watch: {
		'cartItem.quantity': function (newVal) {
			this.orderQty = newVal;
		},
	},

	methods: {
		addItem() {
			this.orderQty = this.minimalOrderQty || 1
			this.setUpdateMode('add')
			this.setDataModified(true)
			this.processEventsDebounced();
		},

		cancelChanges() {
			if (this.cartItem) {
				this.orderQty = this.cartItem.quantity;
			} else {
				this.orderQty = 0
			}
			this.setDataModified(false);
			this.setUpdateMode('')
			this.setIsTyping(false)
		},

		decreaseOrderQuantity() {
			const qty = this.minimalOrderQty || 1
			if (this.orderQty >= qty) {
				this.orderQty -= qty
				this.setDataModified(true)
				this.processEventsDebounced()
			}
		},

		increaseOrderQuantity() {
			const qty = this.minimalOrderQty || 1
			this.orderQty += qty
			this.setDataModified(true)
			this.processEventsDebounced()
		},

		onBlur() {
			if (!this.dataModified) {
				this.isTyping = false
				this.setUpdateMode('')
			}
		},

		onChange() {
			this.setDataModified(true)
			this.processEventsDebounced()
		},

		onFocus() {
			this.isTyping = true
		},

		async processEvents() {
			if (this.isTyping) {
				this.processEventsDebounced()
				return
			}
			if (this.dataModified) {
				await this.saveChanges()
				return
			}
			this.setUpdateMode('')
			this.setIsTyping(false)
		},

		async saveChanges() {
			this.loading = true;

			if (this.minimalOrderQty > 1) {
				let x = Math.trunc(this.orderQty / this.minimalOrderQty);
				let y = this.orderQty % this.minimalOrderQty;
				if (y > 0) {
					this.orderQty = (x + 1) * this.minimalOrderQty;
				}
			}

			let cartItemUpd = {
				cartItemId: this.cartItem ? this.cartItem.cartItemId : "",
				productCode: this.productCode.trim(),
				quantity: Number(this.orderQty)
			};

			this.$store
				.dispatch("shoppingCart/upsertCartItem", cartItemUpd)
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.loading = false
					this.setDataModified(false);
					this.setUpdateMode('')
					this.setIsTyping(false)
				})
		},

		setDataModified(val) {
			this.dataModified = val;
		},

		setIsTyping(val) {
			this.isTyping = val
		},

		setUpdateMode(val) {
			this.updateMode = val;
		},

		updateItem() {
			this.setUpdateMode('update')
			this.processEventsDebounced();
		},

	},
};
</script>
