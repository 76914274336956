<template>
	<v-card v-if="catalogItem" class="mx-auto mt-lg-16" max-width="960" rounded outlined>
		<v-card-title>
			<span>{{ catalogItem.itemName }}</span>
			<v-spacer />
			<v-btn icon v-on:click.stop="$router.go(-1)"><v-icon>mdi-close-circle</v-icon></v-btn>
		</v-card-title>
		<v-card-subtitle>
			<span v-if="catalogItem.brandName">{{ $t('CatalogItem.Brand') }}: {{ catalogItem.brandName }}</span>
		</v-card-subtitle>
		<v-divider />
		<v-card-text>
			<v-row align="center">
				<v-col cols="12" lg="3">
					<v-img v-if="catalogItem.itemImages" :src="catalogItem.itemImages[0].imageUrl" height="250" contain
						position="center center" />
				</v-col>
				<v-col cols="12" lg="9">
					<v-tabs v-model="tab">
						<v-tab :tab-value="Number(1)" v-if="catalogItem.itemDescription">Product info</v-tab>
						<v-tab :tab-value="Number(2)">Bestel info</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<!-- PRODUCT INFO -->
						<v-tab-item :value="Number(1)">
							<v-sheet v-html="catalogItem.itemDescription" height="250" outlined rounded
								class="pa-4 grey lighten-5" style="overflow-y: auto" />
						</v-tab-item>
						<!-- ORDER INFO -->
						<v-tab-item :value="Number(2)">
							<v-sheet height="250" outlined rounded class="pa-4 grey lighten-5" style="overflow-y: auto">
								<v-row>
									<v-col cols="12" lg="6">
										<v-text-field readonly hide-details :label="$t('Product_code')"
											:value="itemSKU.productCode" />
									</v-col>
									<v-col cols="12" lg="6">
										<v-text-field v-show="itemSKU.atpCode" readonly hide-details
											:label="$t('ATP_label')" :value="$t('ATP_status.' + itemSKU.atpCode)" />
									</v-col>
								</v-row>
								<v-row v-if="itemSKU.unitPriceExclVat">
									<v-col cols="12" lg="6">
										<v-text-field readonly hide-details :label="$t('Unit_price')"
											:value="$n(itemSKU.unitPriceExclVat, 'decimal') + ' ' + itemSKU.currency" />
									</v-col>
									<v-col cols="12" lg="6">
										<v-text-field readonly hide-details :label="$t('Vat_percentage')"
											:value="itemSKU.vatPerc" />
									</v-col>
								</v-row>
							</v-sheet>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-btn small plain v-on:click.stop="$router.go(-1)"><v-icon left>mdi-chevron-left</v-icon>
				{{ $t('CatalogItem.Back_to_list') }}
			</v-btn>
			<v-spacer />
			<cart-item-toolbar v-if="itemSKU?.canOrderOnline" :productCode="itemSKU.productCode"
				:minimalOrderQty="itemSKU.minimalOrderQty" />
			<v-btn v-else-if="!requestSent" plain color="primary" :loading="loading" @click="contactRequest">{{
				$t('CatalogItem.Contact_me') }}
			</v-btn>
			<span v-else>{{ $t('CatalogItem.Request_sent') }}</span>
		</v-card-actions>
	</v-card>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import CartItemToolbar from "@/components/CartItemToolbar";
import { functions } from "@/services/firebase"
import { httpsCallable } from "firebase/functions";

export default {
	name: "CatalogItem",

	components: { CartItemToolbar },

	props: {
		id: null,
	},

	data() {
		return {
			loading: false,
			requestSent: false,
			tab: null,
			tenantId: process.env.VUE_APP_TENANT_ID
		};
	},

	computed: {
		...mapGetters({
			catalogItem: "catalog/catalogItem",
			itemSKU: "catalog/catalogItemSKU",
			userId: "session/firebaseUserId",
			locale: "session/locale",
			userName: "session/userName",
			customerName: "session/customerName",
			userEmail: "session/firebaseUserEmail"
		}),
	},

	beforeRouteEnter(routeTo, routeFrom, next) {
		store
			.dispatch("catalog/fetchCatalogItem", Number(routeTo.params.id))
			.then(() => {
				next(); // Only once this is called does the navigation continue
			});
	},

	methods: {
		async contactRequest() {
			this.loading = true
			try {
				const call = httpsCallable(functions, "authentication-portalUsers-contactRequest");
				await call({
					argument: {
						tenantId: process.env.VUE_APP_TENANT_ID,
						mailTo: 'salessupport@' + process.env.VUE_APP_DOMAIN_NAME,
						language: 'nl',
						userName: this.userName,
						userEmail: this.userEmail,
						customerName: this.customerName,
						requestInfo: 'Prijsopgave voor product ' + this.itemSKU.productCode
					}
				});
				this.requestSent = true
			} catch (e) {
				console.error("Error adding document: ", e);
			} finally {
				this.loading = false
			}
		}
	}
};
</script>
